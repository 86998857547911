.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}

.slide-in-right {
    animation: slide-in-right 0.5s forwards;
    -webkit-animation: slide-in-right 0.5s forwards;
}

.slide-out-right {
    animation: slide-out-right 0.5s forwards;
    -webkit-animation: slide-out-right 0.5s forwards;
}

@keyframes slide-in-right {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in-right {
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out-right {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
}

@-webkit-keyframes slide-out-right {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(100%); }
}

.redCrossLine::before {
  border-bottom: 1px solid #f37171;
  width: calc(14px * 1.33);
  border-radius: 1px;
  transform: rotate(-45deg);
  transform-origin: left;
  bottom: 0;
  padding: 1px;
  position: absolute;
  content: '';
}