body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-resizable {
  position: relative;
  transform-style: preserve-3d;
  outline: 1px solid #00a1ff;
}
.react-resizable.noBorder {
  outline: none;
}
.react-resizable-handle {
  position: absolute;    
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background: #fff;
  padding: 0 3px 3px 0;
  outline: 1px solid #00a1ff;
}
.react-resizable-handle-sw {
  bottom: -5px;
  left: -5px;
  cursor: sw-resize;
}
.react-resizable-handle-se {
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: -5px;
  left: -5px;
  cursor: nw-resize;
}
.react-resizable-handle-ne {
  top: -5px;
  right: -5px;
  cursor: ne-resize;
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -5px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: -5px;
}
.react-resizable-handle-e {
  right: -5px;
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -5px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: -5px;
}
.react-resizable-handle-s {
  bottom: -5px;
}
.cursor-rotated-arrow .konvajs-content {
  cursor: inherit!important;
}
.cursor-rotated-arrow-clicked .konvajs-content {
  cursor: inherit!important;
}